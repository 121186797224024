import * as sanitizeHtml from 'sanitize-html';
import parse from "html-react-parser";
import axios from 'axios';

export const getWSConfig = () => {
    let token = localStorage.getItem('accessToken');
    let config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return config;
}

export const getSubDomainChannelView = () => {
    let prefix = window.location.host.split('.')[0];
    let subdomain = null;
    const subdomainmap = {
        fisiotv: {
            channelview: 30,
        },
        pestanaga: {
            channelview: 27,
        }
    };
    if ((prefix != null || prefix != undefined) && (subdomainmap[prefix])) {

        subdomain = subdomainmap[prefix].channelview;

    } else {
        subdomain = null;
    }
    return subdomain;
}

export const isIframe = () => {
    return window.location.pathname.startsWith("/organization/")
}

export const isAnIntegerValue = (text: string) => {
    return /\d/.test(text);
}

/**
 * 
 * @param htmlText a string with html tags and text
 * @returns a parsed html compiled with only safe tags
 */
export const cleanHtmlText = (htmlText) => {
    const cleanHtml = sanitizeHtml(htmlText, {
        allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'br', 'li', 'ol', 'ul', 'u', 'h1', 'h2', 'h3', 'h4', 'h5'],
        allowedAttributes: {
            a: ['href', 'target']
        }
    });
    return parse(cleanHtml);
}

export const getAndStoreKalturaVideoPlayerId = async () => {
    let user = JSON.parse(localStorage.getItem('user'));
    let params = { "organization_id": 0 }

    if (user && user.organization_id) {
        params = { "organization_id": user.organization_id }
    }

    try {
        const response = await axios.post(process.env.REACT_APP_SERVER_URL + '/organization/1.0/get/kaltura/video/player/id', params);
        if (response && response.data) {
            localStorage.setItem('kaltura_video_player_id_cms_portal', JSON.stringify(response.data));
            return JSON.stringify(response.data);
        }
    } catch (error) {
        console.log(error);
        return '';
    }
}

export const deleteKalturaVideoPlayerIdFromLocalStorage = () => {
    localStorage.removeItem('kaltura_video_player_id_cms_portal');
}